/*
If you need to add css to the Logo you can do it here.

.logoMobile {
}
*/

.logoMobile{
    max-width: 50px;
    height: auto;
}